import moment from 'moment';
const locale = process.env.GATSBY_LOCALE_LANGUAGE;

const STRING_PUBLISHED_FR = 'Publié il y a';
const STRING_PUBLISHED_EN = 'Published';

const isToday = timeStamp => {
    const todayStart = moment().startOf('day');
    const todayEnd = moment().endOf('day');
    return moment(timeStamp).isBetween(todayStart, todayEnd);
};

const isYesterday = timeStamp => {
    const yesterdayStart = moment()
        .subtract(1, 'days')
        .startOf('day');
    const yesterdayEnd = moment()
        .subtract(1, 'days')
        .endOf('day');
    return moment(timeStamp).isBetween(yesterdayStart, yesterdayEnd);
};

const isOldDay = timeStamp => {
    const yesterdayStart = moment()
        .subtract(1, 'days')
        .startOf('day');
    return moment(timeStamp).isBefore(yesterdayStart);
};
export const getPublishedTime = date => {
    const now = moment();
    const dateByTimeStamp = transformTimeStamp(date);
    const diff = moment.duration(now.diff(date));
    if (isOldDay(dateByTimeStamp)) {
        return formatDay(Math.ceil(diff.asDays()));
    } else if (isYesterday(dateByTimeStamp)) {
        return getYesterdayStr();
    } else if (isToday(dateByTimeStamp)) {
        if (diff.asHours() <= 24 && diff.asHours() >= 1) {
            return formatHour(Math.ceil(diff.asHours()));
        } else if (diff.asMinutes() < 60 && diff.asMinutes() >= 1) {
            return formatMinute(Math.ceil(diff.asMinutes()));
        } else {
            return getNowStr();
        }
    }
};
export const transformTimeStamp = formatDate => {
    return moment(formatDate).valueOf();
};

const getYesterdayStr = () => (locale === 'fr_FR' ? 'Publié hier' : 'Published yesterday');
const getNowStr = () => (locale === 'fr_FR' ? "Publié à l'instant" : 'Published just now');
const formatMinute = minute =>
    locale === 'fr_FR'
        ? minute > 1
            ? `${STRING_PUBLISHED_FR} ${minute} minutes`
            : `${STRING_PUBLISHED_FR} ${minute} minute`
        : minute > 1
        ? `${STRING_PUBLISHED_EN} ${minute} minutes ago`
        : `${STRING_PUBLISHED_EN} ${minute} minute ago`;
const formatHour = hour =>
    locale === 'fr_FR'
        ? hour > 1
            ? `${STRING_PUBLISHED_FR} ${hour} heures`
            : `${STRING_PUBLISHED_FR} ${hour} heure`
        : hour > 1
        ? `${STRING_PUBLISHED_EN} ${hour} hours ago`
        : `${STRING_PUBLISHED_EN} ${hour} hour ago`;

const formatDay = day =>
    locale === 'fr_FR'
        ? day > 1
            ? `${STRING_PUBLISHED_FR} ${day} jours`
            : `${STRING_PUBLISHED_FR} ${day} jour`
        : day > 1
        ? `${STRING_PUBLISHED_EN} ${day} days ago`
        : `${STRING_PUBLISHED_EN} ${day} day ago`;
