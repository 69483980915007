import React from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';

export const Loader = props => <CircularProgressStyled color="secondary" {...props} />;

const CircularProgressStyled = styled(CircularProgress)`
    ${({ center }) =>
        center &&
        `position: absolute        
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
  `};
`;
